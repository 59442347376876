import * as React from "react";
import { graphql, PageProps } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { getImage } from "gatsby-plugin-image";

// Components
import Layout from "components/Layout";
import Main from "components/Layout/Main";
import SongMedia from "components/Sections/Song/SongMedia";
import SongSidebar from "components/Sections/Song/SongSidebar";
import SongFooter from "components/Sections/Song/SongFooter";
import SongDates from "components/Sections/Song/SongDates";
// Models
import { ISong } from "models/Song";
import { IArtist } from "models/Artist";
import { IUser } from "models/User";
import { IOptionalMetaProps } from "models/Seo";
import { getNamesInLine } from "functions";

type DataProps = {
  song: ISong;
  authors: {
    nodes: IArtist[];
  };
  artists?: {
    nodes: IArtist[];
  };
  translator: IUser;
  translations: {
    count: number;
  };
  articles: {
    count: number;
  };
  collaborators?: {
    nodes: IUser[];
  };
};

const SongTemplate: React.FC<PageProps<DataProps>> = ({ data }) => {
  const {
    title,
    title_jp,
    title_es,
    video,
    covers,
    esp_srt,
    rom_srt,
    thumbnail,
    description,
    date,
    release_date,
    updated,
  } = data.song.frontmatter;
  const { body: lyrics } = data.song;
  const translator = data.translator;
  const collaborators = data.collaborators?.nodes;
  const { artists, translations, articles } = data;
  const authors = data.authors.nodes;

  const authors_names = getNamesInLine(authors);
  const artists_names = artists && getNamesInLine(artists.nodes);

  const songDescription = !description
    ? undefined
    : `Traducción al español de la canción ${title} de ${authors_names}. ${description}.${
        title_jp || title_es ? ` Otros títulos:` : ""
      }${title_jp ? ` ${title_jp}.` : ""}${title_es ? ` ${title_es}.` : ""}`;

  const seo: IOptionalMetaProps = {
    title: `${title} - ${authors_names} - Traducción al español`,
    description:
      songDescription ||
      `Traducción al español de la canción ${title} de ${authors_names}${
        date && `, publicada el ${date}`
      }${artists_names && `, interpretada también por ${artists_names}`}.${
        title_jp || title_es ? ` Otros títulos:` : ""
      }${title_jp ? ` ${title_jp}.` : ""}${title_es ? ` ${title_es}.` : ""}`,
    author: {
      name: translator.name,
      twitter: translator.twitter,
    },
    image: thumbnail?.url,
  };

  return (
    <Layout seo={seo}>
      <Main sidebar>
        <article
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article"
        >
          <h2 className="title">
            {title} - {authors_names}
          </h2>
          {(title_jp || title_es) && (
            <div className="song-titles">
              {title_es && <span className="song-titles__es">{title_es}</span>}
              {title_jp && <span className="song-titles__jp">{title_jp}</span>}
            </div>
          )}
          <header>
            <SongDates release_date={release_date} date={date} />
            <SongMedia
              title={title}
              image={thumbnail}
              authors={authors}
              video={video}
              covers={covers}
              esp_srt={esp_srt}
              rom_srt={rom_srt}
              artists={artists?.nodes}
            />
            <hr />
          </header>
          <MDXRenderer>{lyrics}</MDXRenderer>
          <hr />
          <SongFooter
            slug={data.song.fields.slug}
            message={`${title} - ${authors_names} - Traducción al español`}
          />
          <hr />
        </article>
      </Main>
      <SongSidebar
        authors={authors}
        translator={translator}
        translationsCount={translations.count}
        articlesCount={articles.count}
        collaborators={collaborators}
        artists={artists?.nodes}
      />
    </Layout>
  );
};

export default SongTemplate;

export const pageQuery = graphql`
  query SongPageQuery(
    $slug: String!
    $authors: [String]!
    $translator: String!
    $artists: [String]
    $collaborators: [String]
  ) {
    song: mdx(fields: { slug: { eq: $slug }, collection: { eq: "songs" } }) {
      body
      frontmatter {
        title
        title_es
        title_jp
        description
        date(formatString: "DD [de] MMMM, YYYY", locale: "es")
        updated(formatString: "DD [de] MMMM, YYYY", locale: "es")
        release_date(formatString: "DD [de] MMMM, YYYY", locale: "es")
        video
        artists
        covers
        esp_srt {
          publicURL
        }
        rom_srt {
          publicURL
        }
        translator
        collaborators
        thumbnail {
          url: publicURL
          childImageSharp {
            gatsbyImageData(
              width: 920
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      fields {
        author_slug
        slug
      }
    }

    authors: allArtist(
      filter: { slug: { in: $authors } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        slug
        description
        avatar {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }

    artists: allArtist(
      filter: { hide: { ne: true }, slug: { in: $artists } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        slug
        name
        avatar {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }

    translator: user(slug: { eq: $translator }) {
      slug
      name
      twitter
      facebook
      instagram
      telegram
      youtube
      spotify
      avatar {
        childImageSharp {
          gatsbyImageData(
            width: 100
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }

    translations: allMdx(
      filter: {
        frontmatter: { translator: { eq: $translator } }
        fields: { collection: { eq: "songs" } }
      }
    ) {
      count: totalCount
    }

    articles: allMdx(
      filter: {
        frontmatter: { writer: { eq: $translator }, tags: { ne: "WIP" } }
        fields: { collection: { eq: "articles" } }
      }
    ) {
      count: totalCount
    }

    collaborators: allUser(filter: { slug: { in: $collaborators } }) {
      nodes {
        slug
        name
        avatar {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;
