import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Styles
import * as styles from "./SongDates.module.css"

type SongDatesProps = {
  release_date?: string
  date: string
}

const SongDates: React.FC<SongDatesProps> = ({ release_date, date }) => {
  return (
    <small className={styles.song_dates}>
      {release_date && (
        <span>
          <FontAwesomeIcon icon={["far", "calendar-alt"]} />{" "}
          <time className={styles.song_release_date} title={release_date}>
            {release_date}
          </time>
        </span>
      )}
      <span>
        Traducida el <time title={date}>{date}</time>
      </span>
    </small>
  )
}

export default SongDates
