import * as React from "react"

// Components
import Share from "components/UIElements/Share"

type SongFooterProps = {
  slug: string
  message?: string
}

const SongFooter: React.FC<SongFooterProps> = ({ slug, message }) => {
  return (
    <>
      <h2 className="title">Compartir</h2>
      <Share pathname={`/songs${slug}`} message={message} />
    </>
  )
}

export default SongFooter
